<template>
  <b-container fluid class="report-wrapper">
    <iq-card class="mt-5">
        <template :id="index">
            <b-overlay :show="unitLoad">
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col text-right mt-4 mb-4">
                  <b-button variant="primary" class="mr-4" @click="generateReport"><i class="fa fa-download"> {{ $t('globalTrans.download') }} </i></b-button>
                </div>
              </div>
              <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1200"
                filename="certificate"
                :pdf-quality="2"
                pdf-content-width="100%"
                :manual-pagination="false"
                pdf-format="a4"
                :pdf-orientation="pageType"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf"
                :html-to-pdf-options="htmlToPdfOptions"
                >
                <b-row :class="parseInt(service_id) === 1  ? 'dam' : ''"  slot="pdf-content"  class="p-0 m-0 report-wrapper" :style="pageType === 'landscape' ? 'width: 1124px' : '31cm'">
                  <slot v-if="parseInt(service_id) === 15">
                    <UncontrolledCropVarieties :isAdmin="true" :applicantData="applicationData" />
                  </slot>
                  <slot v-else-if="parseInt(service_id) === 5">
                    <FertilizerImportRegistration :isAdmin="true" :applicantData="applicationData" />
                  </slot>
                  <slot v-else-if="parseInt(service_id) === 6">
                    <FertilizerProductionRegistration :isAdmin="true" :applicantData="applicationData" />
                  </slot>
                  <slot v-else-if="parseInt(service_id) === 23">
                    <FertilizerDistributorRegistration :isAdmin="true" :applicantData="applicationData" />
                  </slot>
                  <slot v-else-if="parseInt(service_id) === 11">
                    <BadcCertificateFertilizer :isAdmin="true" :applicantData="applicationData" />
                  </slot>
                  <slot v-else-if="parseInt(service_id) === 19">
                    <BadcCertificate :isAdmin="true" :applicantData="applicationData" />
                  </slot>
                  <b-col v-else sm="12" style="margin: 0px; padding: 0px">
                    <div v-bind:class="page_pattern">
                      <grid-layout :layout.sync="layout"
                      :col-num="colNum"
                      :row-height="parseInt(service_id) === 1  ? 14 : 12"
                      :margin="parseInt(service_id) === 1  ? [5, 5] : [10, 10]"
                      :is-draggable="false"
                      :is-resizable="false"
                      :vertical-compact="true"
                      :use-css-transforms="true"
                      style="margin:20px 20px 0px 20px; width: 100%">
                        <grid-item style="height: auto !important" v-for="(item, dindex) in layout"
                            :x="item.x"
                            :y="item.y"
                            :w="item.w"
                            :h="item.h"
                            :i="item.i"
                            :key="dindex"
                        >
                        <slot v-if="parseInt(service_id) === 1">
                          <slot v-if="dindex === 47">
                            <b-row>{{ $n(paymentAmount)+ '/-' }}({{ parseFloat(paymentAmount) | convertNumberWords}} {{$t('globalTrans.taka ') + ' ' + $t('globalTrans.only')}})</b-row>
                          </slot>
                          <slot v-else>
                            <commonComponent :Sdata="formInputData[dindex]" :applicantData="applicationData" :layoutData="layoutData" :key="componentKey"/>
                          </slot>
                        </slot>
                        <slot v-else>
                          <commonComponent :Sdata="formInputData[dindex]" :applicantData="applicationData" :layoutData="layoutData" :key="componentKey"/>
                        </slot>
                        </grid-item>
                      </grid-layout>
                    </div>
                  </b-col>
                </b-row>
              </vue-html2pdf>
              <slot v-if="parseInt(service_id) === 15">
                <UncontrolledCropVarieties :isAdmin="true" :applicantData="applicationData" />
              </slot>
              <slot v-else-if="parseInt(service_id) === 5">
                <FertilizerImportRegistration :isAdmin="true" :applicantData="applicationData" />
              </slot>
              <slot v-else-if="parseInt(service_id) === 6">
                <FertilizerProductionRegistration :isAdmin="true" :applicantData="applicationData" />
              </slot>
              <slot v-else-if="parseInt(service_id) === 23">
                <FertilizerDistributorRegistration :isAdmin="true" :applicantData="applicationData" />
              </slot>
              <slot v-else-if="parseInt(service_id) === 11">
                <BadcCertificateFertilizer :isAdmin="true" :applicantData="applicationData" />
              </slot>
              <slot v-else-if="parseInt(service_id) === 19">
                <BadcCertificate :isAdmin="true" :applicantData="applicationData" />
              </slot>
              <slot v-else>
                <b-row class="p-0 m-0" :class="parseInt(service_id) === 1  ? 'dam' : ''">
                    <b-col sm="12">
                      <div v-bind:class="page_pattern" style="margin: auto">
                        <grid-layout :layout.sync="layout"
                        :col-num="colNum"
                        :row-height="parseInt(service_id) === 1  ? 14 : 12"
                        :is-draggable="false"
                        :margin="parseInt(service_id) === 1  ? [5, 5] : [10, 10]"
                        :is-resizable="false"
                        :vertical-compact="true"
                        :use-css-transforms="true"
                        style="margin:20px 20px 0px 20px; width: 100%"
                        >
                          <grid-item style="height: auto !important" v-for="(item, dindex) in layout"
                              :x="item.x"
                              :y="item.y"
                              :w="item.w"
                              :h="item.h"
                              :i="item.i"
                              :key="dindex"
                          >
                            <slot v-if="parseInt(service_id) === 1">
                              <slot v-if="dindex === 47">
                                <b-row>{{ $n(paymentAmount)+ '/-' }}({{ parseFloat(paymentAmount) | convertNumberWords}} {{$t('globalTrans.taka ') + ' ' + $t('globalTrans.only')}})</b-row>
                              </slot>
                              <slot v-else>
                                <commonComponent :Sdata="formInputData[dindex]" :applicantData="applicationData" :layoutData="layoutData" :key="componentKey"/>
                              </slot>
                            </slot>
                            <slot v-else>
                              <commonComponent :Sdata="formInputData[dindex]" :applicantData="applicationData" :layoutData="layoutData" :key="componentKey"/>
                            </slot>
                          </grid-item>
                        </grid-layout>
                      </div>
                    </b-col>
                </b-row>
              </slot>
              <div class="row p-3">
                <div class="col text-right">
                  <b-button @click="backStep()" variant="primary" class="mr-4">{{ $t('globalTrans.back') }}</b-button>
                </div>
              </div>
            </b-overlay>
        </template>
    </iq-card>
  </b-container>
</template>
<script>
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'
import BadcCertificateFertilizer from '@/components/license/badc/BadcCertificateFertilizer'
import UncontrolledCropVarieties from '@/components/license/moa/UncontrolledCropVarieties'
import FertilizerImportRegistration from '@/components/license/dae/FertilizerImportRegistration'
import FertilizerProductionRegistration from '@/components/license/dae/FertilizerProductionRegistration'
import FertilizerDistributorRegistration from '@/components/license/dae/FertilizerDistributorRegistration'
import BadcCertificate from '@/components/license/badc/BadcCertificate'
import commonComponent from '../../components/common/commonComponent'
import { GridLayout, GridItem } from 'vue-grid-layout'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { documentView, staticSeedDealerCertificate } from '../../api/routes'
import { wordsToSnake } from '@/Utils/fliter'
import { isNotEmpty } from '@amcharts/amcharts4/.internal/core/utils/Utils'
import VueHtml2pdf from 'vue-html2pdf'
Vue.use(VueHtmlToPaper)

export default {
  props: ['stepChange', 'fromdetails'],
  components: {
    commonComponent,
    UncontrolledCropVarieties,
    BadcCertificateFertilizer,
    FertilizerImportRegistration,
    FertilizerProductionRegistration,
    FertilizerDistributorRegistration,
    BadcCertificate,
    GridLayout,
    GridItem,
    VueHtml2pdf
  },
  data () {
    return {
        service_id: 0,
        step_id: 0,
        application_id: 0,
        service_name: '',
        step_name: '',
        applicationData: '',
        layoutData: '',
        layout: [],
        formInputData: [],
        colNum: parseInt(this.stepChange.service_id) === 1 ? 20 : 12,
        paymentAmount: 0,
        index: 0,
        unitLoad: false,
        loading: false,
        componentKey: 0,
        alignment: 'A4',
        pageType: 'portrait',
        page_pattern: '',
        pageStyle: {
          styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'
          ]
        }
    }
  },
  created () {
    this.service_id = parseInt(this.stepChange.service_id)
    this.step_id = parseInt(this.stepChange.step_id)
    this.application_id = parseInt(this.stepChange.application_id)
    this.service_name = this.getServiceName(parseInt(this.stepChange.service_id))
    this.step_name = this.getStepName(parseInt(this.stepChange.step_id))
    this.getApplicantDocument()
  },
  computed: {
    htmlToPdfOptions: {
      html2canvas: {
        scale: 1,
        useCORS: true
      }
    }
  },
  methods: {
    generateReport () {
      if (this.service_id === 17) {
        this.pdfDownloadExport()
      } else {
        this.$refs.html2Pdf.generatePdf()
      }
    },
    async pdfDownloadExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, service_id: parseInt(this.stepChange.service_id) })
      this.unitLoad = true

      try {
        const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, staticSeedDealerCertificate + '/' + parseInt(this.stepChange.application_id), params)
        var blob = new Blob([result], { type: 'application/pdf' })
        var url = window.URL.createObjectURL(blob)

        // Create an anchor element for direct download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'seed-dealer-registration-certificate.pdf' // Set the desired filename for the download

        // Trigger a click event on the anchor element to initiate the download
        document.body.appendChild(a)
        a.click()

        // Clean up the anchor element and URL object
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)

        this.unitLoad = false
      } catch (error) {
        this.unitLoad = false
      }
    },
    backStep () {
      if (this.fromdetails !== undefined) { // this will check the source weather it is come from deatails page or List
        this.$router.go(-2)
      } else {
        this.$router.go(-1)
      }
    },
    print () {
      this.$htmlToPaper('printMe', this.pageStyle)
    },
    getApplicantDocument () {
      this.unitLoad = true
      const params = {
        service_id: this.service_id,
        step_id: this.step_id,
        application_id: this.application_id,
        service_name: this.service_name,
        step_name: this.step_name
      }
      RestApi.getData(licenseRegistrationServiceBaseUrl, documentView, params).then(response => {
        if (isNotEmpty(response.data)) {
            const resultData = response.data
            this.paymentAmount = response.payAmount
            this.applicationData = response.data.applicantInfo
            this.layoutData = response.data.mainLayout
            this.alignment = response.data.layout.page_size
            if (this.alignment === 'A4') {
              this.pageType = 'portrait'
            } else {
              this.pageType = 'landscape'
            }
            this.page_pattern = response.data.layout.page_size + '_' + response.data.layout.border_pattern
            const CustomCss = `${licenseRegistrationServiceBaseUrl}${this.pageType}.css`
            this.pageStyle.styles.push(CustomCss)
            resultData.layout.document_layouts.forEach((layItem) => {
                const layJson = JSON.parse(layItem.layout)
                const dataJson = JSON.parse(layItem.data)
                this.layout.push(layJson)
                this.formInputData.push(dataJson)
            })
            this.index = this.layout.length
        } else {
            this.$toast.error({
              title: 'Error',
              message: response.message
            })
            this.backStep()
          }
        this.unitLoad = false
      })
    },
    getServiceName (sId) {
      const service = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === sId)
      return service !== undefined ? wordsToSnake(service.text) : ''
    },
    getStepName (stId) {
      const step = this.$store.state.licenseRegistration.commonObj.stepNamesList.find(item => item.value === stId)
      return step !== undefined ? wordsToSnake(step.text) : ''
    }
  }
}
</script>
<style scoped>
.A4_none {
  width:21cm;
  margin: 20px;
}
.A4_design_one {
  width:21cm;
  height: 1090px;
  margin: auto !important;
  background: url('../../../../../assets/images/design-one-A4.jpg');
  background-size: cover;
  padding: 45px;
  /* border: 10px solid transparent;
  border-image: url('../../../../../assets/images/border.png') 30 repeat;
  border-image-width: 14px; */
}
.A4_design_two {
  width:21cm;
  margin: auto !important;
  background: url('../../../../../assets/images/design-two-A4.jpg');
  background-size: cover;
  border: 10px solid #01035c;
}
.A4_design_two::before {
  content: '';
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  border: 7px solid #564b00;
}
.A4_design_three {
  width:21cm;
  margin: auto !important;
  background: url('../../../../../assets/images/design-three-A4.jpg');
  background-size: cover;
  padding: 20px;
}
.A4_design_four {
  width:21cm;
  margin: auto !important;
  background: url('../../../../../assets/images/design-four-A4.jpg');
  background-size: cover;
}
.landscape_none {
  margin: auto !important;
}
.landscape_design_one {
  position: relative;
  margin: auto !important;
  background: url('../../../../../assets/images/design-one-landscape.jpg');
  background-size: cover;
}
.landscape_design_two {
  position: relative;
  background: url('../../../../../assets/images/design-two-landscape.jpg');
  background-size: cover;
  border: 10px solid #01035c;
  padding: 10px;
  margin: 20px;
}
.landscape_design_two::before {
  content: '';
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  border: 7px solid #564b00;
}
.landscape_design_three {
  position: relative;
  margin: auto !important;
  background: url('../../../../../assets/images/design-three-landscape.jpg');
  background-size: cover;
}
.landscape_design_four {
  position: relative;
  margin: auto !important;
  background: url('../../../../../assets/images/design-four-landscape.jpg');
  background-size: cover;
}
.report-wrapper {
    font-family: "Kalpurush";
    font-size: 1rem
}
.landscape_design_six {
  position: relative;
  background: url('../../../../../assets/images/design-six-landscape.jpg');
  background-size: cover;
  border: 10px solid #01035c;
  padding: 10px;
  margin: 20px;
}
.landscape_design_six::before {
  content: '';
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  border: 7px solid #564b00;
}
/* .A4_design_seven {
  position: relative;
  background: url('../../../../../assets/images/dae-import.jpg');
  background-size: cover;
  border: 10px solid #01035c;
  width: 20cm;
  margin: 20px;
}
.A4_design_seven::before {
  content: '';
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  border: 7px solid #564b00;
} */
</style>
<style>
.dam .vue-grid-item {
  line-height: 18px;
}
.dam .col-sm-12.col-md-12.col-lg-12.right {
  padding: 0px !important;
}
.dam .col-sm-12.col-md-12.col-lg-12.left {
  padding: 0px !important;
}
.dam h4 {
  font-weight: bold !important;
}
</style>
